<template>
  <div class="d-sm-flex justify-space-between my-3">
    <v-subheader>Statut</v-subheader>
    <v-select
      v-model="alertData.relevant_status"
      :items="relevantStatuses"
      label="Non traité"
      solo
      hide-details
      menu-props="closeOnContentClick"
      @change="updateRelevance"
    >
      <template v-slot:selection="{ item }">
        {{ $t(`status.${item}`) }}
      </template>
      <template v-slot:item="{ item }">
        {{ $t(`status.${item}`) }}
      </template>
      <template v-slot:append-item v-if="alertData.relevant_status">
        <v-divider />
        <div
          @click="resetRelevance()"
          class="text--disabled ml-4 mt-2"
          style="cursor: pointer"
        >
          Non traité
        </div>
      </template>
    </v-select>

    <v-spacer />

    <v-subheader>Résultat d'analyse</v-subheader>
    <v-select
      v-model="alertData.result_status"
      :items="resultsList"
      item-value="result_status"
      label="Sélectionner un résultat"
      solo
      hide-details
      menu-props="closeOnContentClick"
      return-object
      @change="updateResult"
    >
      <template v-slot:selection="{ item }">
       {{ $t(`result.${item.result_status}`) }}
      </template>
      <template v-slot:item="{ item }">
       {{ $t(`result.${item.result_status}`) }}
      </template>
      <template v-slot:append-item v-if="alertData.relevant_status">
        <v-divider />
        <div
          @click="resetResult()"
          class="text--disabled ml-4 mt-2"
          style="cursor: pointer"
        >
          Pas de sélection
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      alertData: {
        ...this.value,
        result_status: {
          relevant_status: this.value.relevant_status,
          result_status: this.value.result_status,
        }
      }
    };
  },

  computed: {
    ...mapState("analysis", ["analysisStatuses"]),
    ...mapGetters("analysis", ["relevantStatuses"]),

    resultsList() {
      if (this.alertData.relevant_status) {
        return this.analysisStatuses[this.alertData.relevant_status].map((val) => {
          return {
            relevant_status: this.alertData.relevant_status,
            result_status: val
          };
        });
      }

      return Object.keys(this.analysisStatuses).reduce((acc, val) => {
        acc.push({ header: this.$t(`status.${val}`) });

        this.analysisStatuses[val].forEach(el => {
          acc.push({
            relevant_status: val,
            result_status: el
          });
        });

        return acc;
      }, []);
    }
  },

  methods: {
    updateRelevance: function() {
      this.$store.dispatch("alerts/updateAlertRelevance", {
        alertData: this.alertData,
        relevantStatus: this.alertData.relevant_status
      });
    },

    resetRelevance: function() {
      this.$store.dispatch("alerts/updateAlertRelevance", {
        alertData: this.alertData,
        relevantStatus: null
      });

      this.alertData.relevant_status = null;
      this.alertData.result_status = null;
    },

    resetResult: function() {
      this.$store.dispatch("alerts/updateAlertRelevance", {
        alertData: { ...this.alertData, result_status: null },
        // relevantStatus: null
      });

      this.alertData.result_status = null;
    },

    updateResult(val) {
      this.$store.dispatch("alerts/updateAlertRelevance", {
        alertData: this.alertData,
        relevantStatus: val.relevant_status,
        resultStatus: val.result_status
      });

      this.alertData.relevant_status = val.relevant_status;
      this.alertData.result_status = {
        relevant_status: val.relevant_status,
        result_status: val.result_status
      };
    }
  }
};
</script>